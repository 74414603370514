import './global/js/util/modernizr';
import './global/js/util/polyfills';
import './style.scss';
import './global/js/core/core';

/*
 * Animations
 */
import cursorEffects from './global/js/animations/cursor-effects';
import staggerAnimation from './global/js/animations/gsap/stagger-text';
import parallaxScroll from './global/js/animations/parallax-light';
import progressBar from './global/js/animations/progress';
import letterEffects from './global/js/animations/splittext';
import accordionInit from './global/js/misc/accordion/accordion-init';
/*
 * Misc
 */
import dropdownInit from './global/js/misc/dropdown/dropdown-init';
import externalLinks from './global/js/misc/external-links/external-links';
import customMicromodalCall from './global/js/misc/micromodal/micromodal';
import swiperInit from './global/js/misc/swiper/swiper-init';
import tableInit from './global/js/misc/table/table-init';
/*
 * Atoms
 */
import scrollToTop from './patterns/01-atoms/misc/scroll-to-top-a/scroll-to-top-a-1/scroll-to-top-a-1';
/*
 * Molecules
 */
import multiselectDropdown from './patterns/02-molecules/dropdowns/terms-multiselect-a/terms-multiselect-a-1/terms-multiselect-a-1';
import galleryA from './patterns/02-molecules/galleries/gallery-a/gallery-a-1';
import mapBlock from './patterns/02-molecules/maps/map-a/map-a-1';
import modal from './patterns/02-molecules/modals/modal-a/modal-a-1';
import modalVideoA1 from './patterns/02-molecules/modals/modal-video-a/modal-video-a-1';
import hamburger from './patterns/02-molecules/nav/hamburger-a/hamburger-a-1';
import navMob from './patterns/02-molecules/nav/nav-mobile-a/nav-mobile-a-1';
import { postHeadingNavigationA1 } from './patterns/02-molecules/nav/post-heading-navigation-a/post-heading-navigation-a-1.js';
import searchBar from './patterns/02-molecules/search/search-bar-a/search-bar-a-1';
import { slideOut } from './patterns/02-molecules/slide-outs/slide-out-a/slide-out-a-1';
import socialShare from './patterns/02-molecules/social/social-share-a/social-share-a-1';
import widgetTranslationA from './patterns/02-molecules/widgets/widget-translation/widget-translation-a/widget-translation-a-1/widget-translation-a-1';
/*
 * Organisms
 */
import headerInit from './patterns/03-organisms/single/header/header-init';
/*
 * Plop Entry (do not remove)
 */
/// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
/// /IMPORT FROM PLOP

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
    searchBar,
    scrollToTop,
    socialShare,
    hamburger,
    slideOut,
    modal,
    modalVideoA1,
    mapBlock,
    galleryA,
    multiselectDropdown,
    progressBar,
    postHeadingNavigationA1,
    /// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
    /// /MODULES FROM PLOP
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
const calledModulesNames = [];
modules.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in modules) {
        if ($this.attr('data-initialized') === 'true') {
            return;
        }
        new modules[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw `Module '${module}' not found`;
    }
};

jQuery(document).ready(() => {
    widgetTranslationA($);
    navMob($);
    externalLinks($);
    customMicromodalCall($);

    headerInit();
    dropdownInit();
    swiperInit();
    tableInit();
    accordionInit();

    staggerAnimation();
    /*
     * Loops through component functions that should only run if the component exists in the ajax container or page.
     * The actual check is performed through "data-module";
     */
    $('[data-module]').each(function () {
        if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
            modules.instantiate(this);
            calledModulesNames.push($(this).data('module'));
        }
    });

    modal($);

    /*
     * GSAP based animations. Hide if not used.
     */
    // cursorEffects($);
    // letterEffects($);
});

jQuery(window).scroll(() => {
    parallaxScroll($);
});
