/**
 * Generates a navigation list for headings within a post
 *
 * @function
 */
export function postHeadingNavigationA1() {
    const classes = {
        hook: '.js-post-navigation',
        list: '.js-post-navigation-list',
        content: '.l-main-content',
        link: '.js-post-navigation-link',
        linkActive: 'is-active',
    };

    const ids = {
        title: 'title-chapter-',
    };

    const navigationMolecule = $(classes.hook);
    const navigationList = $(classes.list);

    // Get all headings in the content article
    const headings = navigationMolecule.parent().siblings().find('h2');

    // For each title in the content, generate corresponding link in navigation
    headings.each(function (i) {
        navigationList.append(generateSingleLink(this.innerText, i));
        this.id = `${ids.title}${i}`;
    });

    window.addEventListener('scroll', function () {
        let topHeading = null;
        let topDistance = Infinity;

        // Iterate through all the headings to find the top one
        headings.each(function () {
            const distance = Math.abs(this.getBoundingClientRect().top);

            if (distance < topDistance) {
                topHeading = this;
                topDistance = distance;
            }
        });

        let topHeadingId = $(topHeading).first().attr('id');

        // Remove the active class from all links except the current one
        $(`${classes.link}:not([href="#${topHeadingId}"])`).each(function () {
            $(this).removeClass(classes.linkActive);
        });

        let activeLink = $(`${classes.link}[href="#${topHeadingId}"]`);

        activeLink.addClass(classes.linkActive);

        StickyNav();
    });

    /**
     * Generates a single link for a given heading
     *
     * @function
     * @param {string} text - The text for the link
     * @param {number} i - The index of the heading
     * @returns {HTMLLIElement} The generated link element
     */
    function generateSingleLink(text, i) {
        const headingItem = document.createElement('li');
        const headingLink = document.createElement('a');

        // Set href, class and inner Text
        headingLink.href = `#${ids.title}${i}`;
        headingLink.classList.add(classes.link.slice(1));
        headingLink.innerText = text;

        headingItem.append(headingLink);

        return headingItem;
    }

    function StickyNav() {
        var headerHeight = $('header').outerHeight();
        var DefaultOffset = 20;
        var sideContentWrapper = $('.o-article-single-1__side-content-wrapper');
        var mainSection = $('.o-article-single-1__main');
        var mainSectionOffset =
            mainSection.offset().top - headerHeight - DefaultOffset;
        var mainSectionHeight = mainSection.outerHeight();
        var sideContentWrapperHeight = sideContentWrapper.outerHeight();
        var sideContentWrapperOffset = sideContentWrapper.offset().top;
        var sideContentWrapperWidth = sideContentWrapper.outerWidth(true);
        var isFixed = false;

        $(window).scroll(function () {
            var scrollPosition = $(window).scrollTop();

            if (scrollPosition >= mainSectionOffset && !isFixed) {
                sideContentWrapper.css({
                    position: 'fixed',
                    left: mainSection.offset().left - sideContentWrapperWidth,
                    top: headerHeight + DefaultOffset,
                });
                isFixed = true;
            }

            if (isFixed && scrollPosition < mainSectionOffset) {
                sideContentWrapper.css({
                    position: 'absolute',
                    top: '0',
                    left: '',
                });
                isFixed = false;
            }

            if (
                isFixed &&
                scrollPosition > mainSectionOffset + mainSectionHeight
            ) {
                sideContentWrapper.css({
                    position: 'absolute',
                    top:
                        mainSectionHeight -
                        sideContentWrapper.outerHeight() +
                        'px',
                    left: '',
                });
                isFixed = false;
            }

            if (
                isFixed &&
                scrollPosition >
                    mainSectionOffset +
                        mainSectionHeight -
                        sideContentWrapperHeight
            ) {
                var maxTopPosition =
                    mainSectionHeight - sideContentWrapper.outerHeight();
                var newTopPosition = Math.min(
                    maxTopPosition,
                    scrollPosition - mainSectionOffset,
                );

                sideContentWrapper.css({
                    position: 'absolute',
                    top: newTopPosition + 'px',
                    left: '',
                });
                isFixed = false;
            }
        });
    }

    AnchorOffser();

    function AnchorOffser() {
        $('.js-post-navigation-link').click(function (e) {
            e.preventDefault();

            var href = $(this).attr('href');
            var target = $(href);
            var headerHeight = $('header').outerHeight();
            window.location.hash = href;

            $(window).scrollTop(target.offset().top - headerHeight);
        });
    }
}
