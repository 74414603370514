/**
 * Initializes the search bar functionality.
 */
const searchBar = () => {
    /**
     * jQuery object representing the search bar element.
     * @type {JQuery<HTMLElement>}
     */
    const searchBar = $('.m-search-bar');

    /**
     * jQuery object representing the autocomplete element.
     * @type {JQuery<HTMLElement>}
     */
    const algoliaAutocomplete = $('.algolia-autocomplete');

    /**
     * Flag indicating whether the search bar is active.
     * @type {boolean}
     */
    let isSearchBarActive = searchBar.hasClass('m-search-bar--active');

    /**
     * Toggles the search bar visibility and behavior.
     */
    const toggleSearchBar = () => {
        if (!isSearchBarActive) {
            $('.js-header-search-toggle').toggleClass(
                'a-button-search--active',
            );
            searchBar.addClass('m-search-bar--active').slideDown(400, () => {
                // Animation complete callback
                $('body').addClass('h-scroll-lock');
            });

            if (algoliaAutocomplete.css('display') === 'block') {
                algoliaAutocomplete.css('display', 'none');
            }

            if (searchBar.hasClass('m-search-bar--active')) {
                setTimeout(() => {
                    $('.m-search-bar__search-field').focus();
                }, 600);
            }
        }

        isSearchBarActive = !isSearchBarActive; // Toggle search bar active state
    };

    /**
     * Toggles the mobile search bar visibility and behavior.
     */
    const toggleMobileSearchBar = () => {
        $('.js-slide-out-search-wrapper-mob').toggleClass(
            'c-slide-out__search-wrapper--active',
        );
        setTimeout(() => {
            $('.js-search-field-mob').focus();
        }, 600);
    };

    /**
     * Handles the keyup event of the search field.
     */
    const handleSearchFieldKeyup = () => {
        const searchField = $('.m-search-bar__search-field');
        const popularSearches = $('.m-popular-searches');
        const searchContainer = searchField.closest('.m-search-bar--1');

        if (searchField.val().length) {
            popularSearches.css('display', 'none');
            searchContainer.addClass('m-search-bar--focus');
        } else {
            popularSearches.css('display', 'block');
            searchContainer.removeClass('m-search-bar--focus');
        }
    };

    /**
     * Handles the click event of the search bar close button.
     */
    const handleSearchBarClose = () => {
        const searchField = $(this).siblings('.m-search-bar__search-field');
        searchField.val('').focus();
        $('.m-popular-searches').css('display', 'block');
        searchField
            .closest('.m-search-bar--1')
            .removeClass('m-search-bar--focus');
        algoliaAutocomplete.css('display', 'none');
    };

    /**
     * Handles the mouseup event outside the search bar to close it.
     * @param {Event} e - The mouseup event object.
     */
    const handleDocumentMouseUp = (e) => {
        if (
            !searchBar.is(e.target) &&
            searchBar.has(e.target).length === 0 &&
            searchBar.hasClass('m-search-bar--active')
        ) {
            $('.m-search-bar').slideUp(400, () => {
                // Animation complete callback
                searchBar.removeClass('m-search-bar--active');
                $('body').removeClass('h-scroll-lock');
                isSearchBarActive = false; // Update search bar active state
            });
        }
    };

    $('.js-header-search-toggle').on('click', toggleSearchBar);
    $('.js-header-search-toggle-mobile').on('click', toggleMobileSearchBar);
    $('.m-search-bar__search-field').keyup(handleSearchFieldKeyup);
    $('.m-search-bar__close-btn').on('click', handleSearchBarClose);
    $(document).on('mouseup', handleDocumentMouseUp);
};

export default searchBar;
