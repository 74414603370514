/* ========================================================================
 * Core
 * ======================================================================== */

/**
 * PictureFill + LazySizes + WOW
 *
 * NOTE: You should not need to modify
 * this file!
 *
 * The libraries listed below are used in almost ALL DOD projects.
 */

/**
 * Responsive Images Polyfill
 */
import 'picturefill';
/**
 * Lazyload images:
 * @see https://github.com/aFarkas/lazysizes
 */
import 'lazysizes';

document.addEventListener('lazyloaded', function (e) {
    if (e.target.closest('.js-lazyload-wrapper')) {
        e.target
            .closest('.js-lazyload-wrapper')
            .classList.add('js-lazyload-wrapper-loaded');
    }
});

//
/**
 *  Animate On Scroll. Animations are being applied as you scroll down
 */
/**
 *  Animate On Scroll responsive! With this module you can override the attributes of AOS as you would do it in CSS
 */
import AOS from '@dartmoon/aos-responsive';

/**
 * Init AOS
 */
AOS.init({
    mirror: false,
    once: true, // whether animation should happen only once - while scrolling down
    duration: 900, // values from 0 to 3000, with step 50ms
});
