import Helpers from '/src/global/js/helpers/helpers.js';

import Accordion from '../../vendors/dod-accordion/dod-accordion';

function accordionInit() {
    const allAccordionBlocks = document.querySelectorAll('[data-accordion]');

    // exit function if there isn't any accordion
    if (allAccordionBlocks.length === 0) {
        return null;
    }

    allAccordionBlocks.forEach(function (accordion) {
        const params = Helpers.getJsonFromAttr(accordion.dataset.accordion);

        Accordion(accordion, params);
    });
}

export default accordionInit;
